import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import Home from '../pages/Home';
import Contact from '../pages/Contact';
import About from '../pages/About';
import Price from '../pages/Price';
import Services from '../pages/Services';



const Routers =()=> {
  return (
    <Routes>
    <Route path='/' element={<Navigate to='/Home'/>} />
      <Route path='home' element={<Home/>} />
      <Route path='contact' element={<Contact/>} />
      <Route path='about' element={<About/>} />
      <Route path='price' element={<Price/>} />
      <Route path='services' element={<Services/>} />
    </Routes>
  )
}
export default Routers;