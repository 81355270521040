import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import {Helmet}from"react-helmet";
import icon1 from '../images/Icon (8).png'
import icon2 from '../images/Icon (9)-min.png'
import icon3 from '../images/Icon (10)-min.png'
import icon4 from '../images/Icon (11)-min.png'
import icon5 from '../images/Image (12)-min.png'
import sec6a from '../images/Thumbnail (1)-min.png'
import sec6b from '../images/Thumbnail (2)-min.png'
import sec6c from '../images/Thumbnail-min.png'
import ic1 from '../images/ic1.png'
import ic2 from '../images/ic2.png'
import ic3 from '../images/ic3.png'
import ic4 from '../images/ic4.png'
import ic5 from '../images/ic5.png'
import ic6 from '../images/ic6.png'
import ic7 from '../images/ic7.png'
import ic8 from '../images/ic8.png'
import ic9 from '../images/ic9.png'
import ic10 from '../images/ic10.png'
import ic11 from '../images/ic11.png'
import ic12 from '../images/ic12.png'
import ic13 from '../images/ic13.png'
import phn from '../images/Icon + Container.png'


import imgsec5 from '../images/Image (13)-min.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Home =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Lavouche</title>
      <meta name="description" content="Streamline your legal document review with Verilex. Expert services for efficient, accurate, and reliable reviews across the USA. Get started today!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' >
      <div className='container_top'>
        <h4>Maintenances  &nbsp;&nbsp;.&nbsp;&nbsp; Repairs &nbsp;&nbsp;.&nbsp;&nbsp; Improvements</h4>
      <br />
      <h1>Facility Management Solutions Tailored for Corporate Environments</h1>
     <br />
     <p>Whether it’s routine maintenance or urgent repairs, Lavouche Enterprises is your trusted partner for professional and reliable facility services that keep your business running smoothly.</p>
      <h3 style={{display:"none"}}> <i class="ri-check-fill"></i> Free  Quotes   &nbsp;&nbsp;&nbsp;<i class="ri-check-fill"></i>  100% Commitment-Free </h3>
      <br />

      <button>Call Us Now &nbsp; <span><img  style={{width:"24%"}} src={phn}/> </span></button>
      <br /><br /><br /><br /><br />
      <div style={{width:"65%",margin:"auto"}}>
      <div className='row__mini'>
        <div className='col__mini'>
        <div className='row__min2'>
          <div className='col__mini2'>
            <img src={icon2} alt="" />
          </div>
          <div className='col__mini2'>
          <p>Available <br /> 24/7
          </p>
          </div>
          </div>

        </div>
        <br />
        <div className='col__mini'>
        <div className='row__min2'>
          <div className='col__mini2'>
            <img src={icon3} alt="" />
          </div>
          <div className='col__mini2'>
          <p>Nationwide <br /> Coverage
          </p>
          </div>
          </div>

        </div>
        <br />
        <div className='col__mini'>
        <div className='row__min2'>
          <div className='col__mini2'>
            <img src={icon4} alt="" />
          </div>
          <div className='col__mini2'>
          <p>Certified Commercial <br /> Experts
          </p>
           </div>
          </div>

        </div>
        </div>
      </div>


      </div>
    
    </section>
<br /><br /><br />

    <section className=''>
    <div className='sec2'>
    <div className='row__sec2'>
      <div className='col__sec2'>
        <h2>Professional Facility Management for Corporate Clients</h2>
        <br />
        <p>Lavouche Enterprises specializes in delivering high-quality facility management services for commercial properties, retail chains, and corporate offices. We understand the unique demands of businesses and offer tailored solutions to maintain an optimal work environment. From complex systems maintenance to urgent repairs, we have the expertise and resources to support your business operations with minimal disruption.</p>
<br />
       <div className='rhome'>
        <div className='col_h'><h3>Plumbing & Electrical Services </h3></div>
        <div className='col_h'><h3>Commercial Roofing Repair</h3></div>
       </div>
       <br />
       <div className='rhome'>
        <div className='col_h'><h3>HVAC System Maintenance </h3></div>
        <div className='col_h'><h3>Facility Cleaning & Janitorial Services </h3></div>
       </div>
       <br />
             <div className='rhome'>
        <div className='col_h'><h3>Building Maintenance & Repairs </h3></div>
        <div className='col_h'><h3>Emergency Response Services </h3></div>
       </div>
        <br /> <br />
        <div className='rond'>
        Get a Free Quote Today        </div>
      </div>
      <br />
      <div className='col__sec2'>
        <img src={icon5} alt="" />
      </div>
    </div>
    </div>
    </section>
<br /><br /><br />
    <section>
      <div className='sec3'>
        <h2>Comprehensive Commercial Facility Solutions</h2>
        <p>Meeting the Facility Needs of Large Businesses and Enterprises</p>
<br /><br />
        <div className='row__sec3'>
          <div className='col__sec3'>
            <img src={ic1} alt="" />
            <h3>Plumbing & Electrical Services 
            </h3>
            <p>Comprehensive solutions for maintaining critical infrastructure, ensuring compliance and functionality. </p>
          </div>
          <br />
          <div className='col__sec3'>
            <img src={ic2} alt="" />
            <h3>Commercial Roofing Repair</h3>
            <p> 
Protect your business assets with reliable roofing maintenance, repair, and inspection services. 
 </p>
          </div>
          <br />
          <div className='col__sec3'>
            <img src={ic3} alt="" />
            <h3>HVAC System Maintenance</h3>
            <p> 
Keep your commercial heating and cooling systems running efficiently for a comfortable work environment. 

</p>
          </div>
          <br />
        </div>
        <br />
        <div className='row__sec3'>
          <div className='col__sec3'>
            <img src={ic5} alt="" />
            <h3>Janitorial Services</h3>
            <p> 
Professional cleaning solutions to uphold your company’s reputation with pristine, welcoming spaces. 

</p>
          </div>
          <br />
          <div className='col__sec3'>
            <img src={ic6} alt="" />
            <h3>General Building Maintenance</h3>
            <p> 
Preventive maintenance and routine repairs to minimize operational downtime and extend facility lifespan. 

</p>
          </div>
          <br />
          <div className='col__sec3'>
            <img src={ic7} alt="" />
            <h3>Emergency Response Services</h3>
            <p> 
            Rapid response for urgent facility issues, restoring business continuity with minimal disruption.</p>
          </div>
 
        </div>

      </div>
    </section>
    <br /><br /><br />
    <section className='section4'>
      <div className='section4__div'>
  <br /><br />
      <div className='sec4__row'>
    <div className='sec4__col'>
     <h2>Fast, Reliable, and Professional Service Every Time</h2>
  </div>
  <div className='sec4__col'>
  
     <p>Our team is dedicated to providing high-quality service to our corporate clients. We offer flexible solutions designed to fit the demands of commercial properties, ensuring your business remains operational and compliant.</p>
     </div>
     </div>
 
     <div className='sec4__row'>
   
    <div className='sec4__col'>
    <br /><br />
     <div className='sec4a__row'>
      <div className='sec4a__col'>
       <img src={ic8} alt="" />
      </div>
      <div className='sec4a__colb'>
        <h3>Satisfaction Guarantee</h3>
        <p>We guarantee top-notch service that meets the unique needs of commercial environments</p>
      </div>

     </div>
     <br />
     <div className='sec4a__row'>
      <div className='sec4a__col'>
       <img src={ic9} alt="" />
      </div>
      <div className='sec4a__colb'>
        <h3>Certified Technicians 
        </h3>
        <p>All our professionals are certified, experienced, and trained in handling large-scale commercial projects.</p>
      </div>

     </div>
     <br />
     <div className='sec4a__row'>
      <div className='sec4a__col'>
       <img src={ic10} alt="" />
      </div>
      <div className='sec4a__colb'>
        <h3>Flexible Scheduling 
        </h3>
        <p>We work around your business hours to minimize disruption to your operations. </p>
      </div>

     </div>
     
    </div>
    <div className='sec4__col'>
<br /><br />      
      <div className='sec4a__row'>
      <div className='sec4a__col'>
       <img src={ic11} alt="" />
      </div>
      <div className='sec4a__colb'>
        <h3>Free Quotes 
        </h3>
        <p>Get a detailed estimate and scope of work before any project begins, with transparent pricing.</p>
      </div>

     </div>   
     <br /> 
      <div className='sec4a__row'>
      <div className='sec4a__col'>
       <img src={ic12} alt="" />
      </div>
      <div className='sec4a__colb'>
        <h3>24/7 Availability 
        </h3>
        <p>Our team is always on standby to handle emergencies and keep your business running smoothly.</p>
      </div>

     </div> 
<br />
     <div className='sec4a__row'>
      <div className='sec4a__col'>
       <img src={ic13} alt="" />
      </div>
      <div className='sec4a__colb'>
        <h3>Environmentally Safe Solutions 
        </h3>
        <p>We use sustainable, environmentally-friendly products and processes for all our services. </p>
      </div>

     </div> 
    </div>
     </div>
     </div>
    </section>
<br /><br /><br />
    <section >
      <div className='sec5__div'>
        <div className='sec5__row'>
         <div className='sec5__col'>
          <img src={imgsec5} alt="" />
         </div>
         <div className='sec5__col'>
          <h2>How Lavouche Works for Corporate Clients</h2>
       <br />
          <div className='row__sec5b'>
            <div className='col__sec5bb'>
              <h3>Step 1:</h3>
            
            </div>
            <div className='col__sec5b'>
            <p>Call us at [Insert Phone Number] to discuss your facility needs.</p>
            </div>
          </div>
          <br />
          <div className='row__sec5b'>
            <div className='col__sec5bb'>
              <h3>Step 2:</h3>
            
            </div>
            <div className='col__sec5b'>
            <p>Schedule a service appointment that suits your business hours</p>            </div>
          </div>
          <br />
          <div className='row__sec5b'>
            <div className='col__sec5bb'>
              <h3>Step 3:</h3>
            
            </div>
            <div className='col__sec5b'>
              <p>Our certified team will complete the job efficiently, ensuring minimal impact on your operations</p>
            </div>
          </div>
         </div>
        </div>
      </div>
    </section>
<br /><br /><br />
<section>
<div className='sec6'>
<h2>Facility Management Insights for Corporate Clients</h2>
<p>Stay up-to-date with the latest trends and best practices in commercial facility management. </p>
<br />
<div className='sec6__row'>
<div className='sec6__col'>
<img src={sec6a} alt="" />
<br /><br />

<h3>Innovative HVAC Maintenance Strategies for Large Businesses </h3>
<p>Discover cutting-edge strategies to keep your heating and cooling systems running efficiently.</p>
</div>
<br />
<div className='sec6__col'>
<img src={sec6b} alt="" />
<br /><br />

<h3>How to Ensure Compliance and Safety in Commercial Properties </h3>
<p>Understand the key compliance factors and safety measures every business should consider.  </p>
</div>
<br />
<div className='sec6__col'>
<img src={sec6c} alt="" />
<br /><br />

<h3>Effective Facility Management to Maximize Business Efficiency 
</h3>
<p>Tips and best practices to streamline your facility operations and reduce downtime. </p>
</div>
<br />
</div>
<br /><br />
<h4>View More</h4>
</div>
</section>
<br /><br />
<section>
  <div className='sec7'>
   <div className='sec7__row'>
    <div className='sec7__col'>
    <h2>Frequently Asked Questions for Corporate Clients</h2>
    <p>Still need help? <span>Get Help Now</span></p>  
    </div>
    <div className='sec7__col'>
    <div className='faq__sec'>
    <br />

  <details>
  <summary data-aos="fade-right">What is Lavouche Enterprises? 
  </summary>
  <div>
  <p>Lavouche Enterprises is a professional facility management company offering a wide range of services to corporate clients, including retail chains, office complexes, and industrial facilities.  </p>
  </div>
</details>
<br />
<details>
<summary data-aos="fade-right">Are your services tailored for corporate and commercial properties? 
</summary>
  <div>
  <p>Yes, we specialize in providing solutions that meet the specific needs of commercial and industrial environments.  </p>
  </div></details>
<br />
<details>
<summary data-aos="fade-right">What if I have an issue or complaint? 
 
</summary>  <div>
<p>We are committed to your satisfaction. Contact our dedicated support team, and we’ll address any concerns promptly.   </p>
</div>
</details>
<br />
<details>
<summary data-aos="fade-right">How do I pay for services? 
</summary>  <div>
<p>We offer various payment options and flexible billing cycles for corporate clients, including net terms and scheduled payments.. </p>
</div>

</details>
<br />
<details>
<summary data-aos="fade-right">How do I leave a review for a service provider? 
</summary>  <div>
<p>You can leave a review through our website or reach out to your dedicated account manager. 

Shape  </p>
</div>

</details>
<br />

  </div>
    </div>
   </div>
  </div>

</section>
<br /><br />
<section className='sec8'>
<div className='sec8__div'>
  <h2>Ready to Elevate <br />Your Facility Management? </h2>
  <br />
  <p>Our team is standing by to discuss your facility <br /> needs and develop a custom solution for your business. </p>
 <br />
  <button>Call Us Now</button>
</div>
</section>
        </div>
  )
}
export default Home;