import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/service.css'
import {Helmet}from"react-helmet";
import ic5 from '../images/serv.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

 const Services =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Lavouche</title>
      <meta name="description" content="Streamline your legal document review with Verilex. Expert services for efficient, accurate, and reliable reviews across the USA. Get started today!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' >
      <div className='container_top'>
      <h1 style={{paddingTop:"240px"}}>Services</h1>


      </div>
    
    </section>
<br /><br /><br />

<section>
<div className='service'>
  <div className='ser_row'>
    <div className='sec_cola'>
      <img src={ic5} alt="" />
    </div>
    <div className='sec_colb'>
    <h2>Who We Are</h2>
    <p>Lavouche Enterprises is a trusted provider of professional facility management services, focusing on corporate and commercial clients. Our goal is to help businesses maintain smooth operations through reliable, high-quality facility maintenance, repairs, and support services. We understand the complexities of managing large-scale facilities and provide tailored solutions that ensure your properties are safe, compliant, and efficiently managed. </p>    </div>
  </div>
</div>
</section>
<br /><br /><br />
<section>

  <div className='serv2'>
  <h2>Comprehensive Facility Management for Corporate Clients</h2>
  <br />
  <p>We provide a range of professional facility management services to ensure your business operations run smoothly and efficiently. Our solutions are tailored to meet the needs of commercial and corporate clients, delivering quality service you can rely on, every time.</p>
   <br />
    <div className='serv2_row'>
      <div className='serv2_col'>
        <h2>Janitorial Services</h2>
        <br />
        <p>Keep your commercial spaces spotless and welcoming with our scheduled cleaning services</p>
      </div>
      <br />
      <div className='serv2_col'>
        <h2>HVAC Maintenance & Repairs</h2>
        <br />
        <p>Ensure optimal performance of your heating and cooling systems for a comfortable business environment.</p>
      </div>
      <br />
      <div className='serv2_col'>
        <h2>Plumbing Services</h2>
        <br />
        <p>Prevent disruptions with reliable plumbing repairs and maintenance to keep your operations flowing smoothly.</p>
      </div>

    </div>
    <br />
    <div className='serv2_row'>
      <div className='serv2_col'>
        <h2>Electrical Services</h2>
        <br />
        <p>Expert electrical solutions to ensure safety, compliance, and uninterrupted power for your facility</p>
      </div>
      <br />
      <div className='serv2_col'>
        <h2>General Maintenance & Repairs 
        </h2>
        <br />
        <p>From minor fixes to major repairs, we handle all maintenance tasks to keep your facility running at its best.</p>
      </div>
      <br />
      <div className='serv2_col'>
        <h2>Emergency Services (Priority Service)</h2>
        <br />
        <p>When every second counts, our rapid response team is ready to restore your operations with minimal downtime.</p>
      </div>

    </div>
  </div>
</section>
<section className='sec8'>
<div className='sec8__div'>
  <h2>Already to improve <br /> or repair your home?<br /> Let’s Talk! </h2>
  <br />
  <p>Free  Quotes 100%  Commitment-Free </p>
 <br />
  <button>Call Us Now</button>
</div>
</section>
        </div>
  )
}
export default Services;