import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import logo from './logo.png';

 const Footer =()=> {
  return (
    <>
      <section className="footer__section">
      <div className="footer__width">

        <div className='ft__bx'>
        <h2>Ready to Improve or Upgrade Your Facility? Let’s Talk!</h2>
        <p>From routine maintenance to specialized services, Lavouche Enterprises is your partner for efficient and professional facility management. Contact us today to learn more about how we can support your business. </p>
         </div>
      <div className="row">

        <div className='footer_col' >
          <br /><br />
        <img src={logo} style={{width:"30%"}} alt="logo"></img>

          <p>We offer free quotes and custom service plans tailored to fit your corporate needs.


 </p>
        </div>
 
        <div className='footer_col'  >
          <br /><br />
        <h3 id='tet' >Pages</h3>

        <a id='tet' href='about' className='link__footer'>About Us</a>
        <a id='tet' href='contact' className='link__footer'>Contact Us</a>
        
        </div>
 
        <div className='footer_col' >
        <br /><br />
        <h3>Contact</h3>
        <div className='contact_detil'>
        <i class="ri-phone-fill"></i>
        <span>
        <a href="tel:346 387 7187" >&nbsp;&nbsp;346-387-7187</a>
        </span>
      
        </div>
        <div className='contact_detil'>
        <i class="ri-mail-fill"></i>
        <span>  <a href="mailto:info@lavoucheenterprises.com" >&nbsp;&nbsp; info@lavoucheenterprises.com</a>
        </span>
        </div>
        <div className='contact_detil'>
        <i class="ri-linkedin-fill"></i>
        <span>  <a href="https://www.linkedin.com/company/verilex-corp/" >&nbsp;&nbsp; verilex-corp</a>
        </span>
        </div>
        <div className='contact_detil'>
        <i class="ri-map-pin-fill"></i>
        <span>24285 Katy Freeway, Suite 300 Katy TX 77494 </span>
        </div>     

        </div>
 
        </div>
        </div>

      </section> 
    </>
  )
}
export default Footer;