import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/About.css'
import {Helmet}from"react-helmet";
import ic5 from '../images/abt.png'
import AOS from 'aos';
import 'aos/dist/aos.css';

 const About =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Lavouche </title>
      <meta name="description" content="Streamline your legal document review with Verilex. Expert services for efficient, accurate, and reliable reviews across the USA. Get started today!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' >
      <div className='container_top'>
      <h1 style={{paddingTop:"240px"}}>About Lavouche <br /> Enterprises</h1>


      </div>
    
    </section>
<br /><br /><br />


<section>
<div className='abt__div'>
<div className='abt_row'>
  <div className='abt_cola'>
   <img src={ic5} alt="" />
  </div>
  <div className='abt_colb'>
    <br />
    <h2>Who We Are</h2>
    <p>Lavouche Enterprises is a trusted provider of professional facility management services, focusing on corporate and commercial clients. Our goal is to help businesses maintain smooth operations through reliable, high-quality facility maintenance, repairs, and support services. We understand the complexities of managing large-scale facilities and provide tailored solutions that ensure your properties are safe, compliant, and efficiently managed. </p>
    <h2>Our Commitment to Excellence</h2>
    <p>We strive to be more than just a service provider—we’re your trusted partner, committed to delivering reliable and efficient facility management solutions that align with your business goals. With a focus on quality and consistency, our team is dedicated to maintaining the highest standards of service in every project we undertake. </p>
  </div>
</div>

</div>
</section>
<br /><br /><br />
<section>
  <div className='abt__div2'>
    <h2>Why Choose Lavouche Enterprises? </h2>
    <br />
    <div className='abt_row'>
      <div className='abt_col2'>
        <h2>Expertise </h2>
        <p>Our team of certified professionals brings years of experience and in-depth knowledge to handle all your facility needs efficiently. </p>
      </div>
      <div className='abt_col2'>
        <h2>Client-Centric Approach </h2>
        <p> 
        Your business is our priority. We work closely with you to understand your requirements and customize our services accordingly. </p>
      </div>
      <div className='abt_col2'>
        <h2>Transparency</h2>
        <p> 

        We believe in open communication and provide clear, upfront pricing so you always know what to expect—no surprises, just results. </p>
      </div>
    </div>
    <br />
    <div className='abt_row'>
      <div className='abt_col2'>
        <h2>Innovation </h2>
        <p> 
        We leverage the latest technology and industry best practices to offer cutting-edge solutions for facility management. </p>
      </div>
      <div className='abt_col2'>
        <h2>Reliability</h2>
        <p> 
         
        With 24/7 availability, we are always ready to address your needs, ensuring your facilities are in optimal condition. </p>
      </div>
      <div className='abt_col2'>
        <h2> Commitment to Quality 
        </h2>
        <p> 
        From routine maintenance to complex repairs, we uphold a strong commitment to delivering quality service every time.  </p>
      </div>
    </div>
  </div>
</section>
<br /><br /><br />
<section className='sec8'>
<div className='sec8__div'>
  <h2>Ready to Elevate <br />Your Facility Management? </h2>
  <br />
  <p>Our team is standing by to discuss your facility <br /> needs and develop a custom solution for your business. </p>
 <br />
  <button>Call Us Now</button>
</div>
</section>
        </div>
  )
}
export default About;