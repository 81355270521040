import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/contact.css'
import {Helmet}from"react-helmet";

import AOS from 'aos';
import 'aos/dist/aos.css';

 const Contact =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Efficient Legal Document Review in USA| Verilex</title>
      <meta name="description" content="Streamline your legal document review with Verilex. Expert services for efficient, accurate, and reliable reviews across the USA. Get started today!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' >
      <div className='container_top'>
      <h1 style={{paddingTop:"240px"}}>Contact Us</h1>


      </div>
    
    </section>
<br /><br /><br />


<section>
<div className='con__div'>
<div className='con__row'>
  <div  className='cont__col'>
    <h2>Get In Touch with Lavouche Enterprises</h2>
    <p style={{color:" #C9C9C9"}}> </p>
<br />
    <p style={{display:"flex",alignItems:"center"}}>< i class="ri-phone-fill"></i><a href="tel:346-387-7187" >&nbsp;346-387-7187</a></p>
    <br />
    <p style={{display:"flex",alignItems:"center"}}>< i class="ri-mail-fill"></i>&nbsp;<a href="mailto:info@lavoucheenterprises.com">info@lavoucheenterprises.com</a></p>
    <br />
    <p style={{display:"flex",alignItems:""}}>< i class="ri-map-pin-fill"></i>&nbsp;24285 Katy Freeway, <br /> Suite 300 Katy TX 77494</p>
  </div>
  <div  className='cont__colb'>
    <div className='con__row'>
      
      
        <div className='con__coll'>
          <label htmlFor="">First Name</label>
          <br />
          <input type="text" />
        </div>
        <br />
        <div className='con__coll'>
        <label htmlFor="">Last Name</label>
        <br />
          <input type="text" />

        </div>
    </div>
    <br />
    <div className='con__row'>

      
      
      <div className='con__coll'>
        <label htmlFor="">Email</label>
        <br />
        <input type="text" />
      </div>
      <br />
      <div className='con__coll'>
      <label htmlFor="">Phone Number</label>
      <br />
        <input type="text" />

      </div>
  </div>
  <br />
  <label id='btt' htmlFor="">Message</label>
  <br />
  <input type="text" />
  <br /><br />
  <div style={{textAlign:"right"}}>
  <button >Send Message</button>

  </div>
   </div>
</div>

</div>
</section>
<br /><br /><br />
<section className='sec8'>
<div className='sec8__div'>
  <h2>Already to improve <br /> or repair your home?<br /> Let’s Talk! </h2>
  <br />
  <p>Free  Quotes 100%  Commitment-Free </p>
 <br />
  <button>Call Us Now</button>
</div>
</section>
        </div>
  )
}
export default Contact;