import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/price.css'
import {Helmet}from"react-helmet";

import AOS from 'aos';
import 'aos/dist/aos.css';

 const Price =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Lavouche</title>
      <meta name="description" content="Streamline your legal document review with Verilex. Expert services for efficient, accurate, and reliable reviews across the USA. Get started today!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>
    <section className='header_top' >
      <div className='container_top'>
      <h1 style={{paddingTop:"240px"}}>Price</h1>


      </div>
    
    </section>
<br /><br /><br />

<section>
  <div className='price'>
    <div className='pr__bx'>
      <div className='pr_row'>
        <div className='pr_col'>
          <h2>Janitorial Services</h2>
          <br />
          <p>For individuals that need advanced recording & editing.</p>
        </div>
        <div  id='rght' className='pr_col'>
          <h2 id='rght'>$125/hour</h2>
          <br />
          <button >Call Now</button>
        </div>
      </div>
    </div>
    <br />
    <div className='pr__bx'>
      <div className='pr_row'>
        <div className='pr_col'>
          <h2>HVAC Maintenance & Repairs</h2>
          <br />
          <p>FFor teams that need advanced sharing & reporting.
          </p>
        </div>
        <div  id='rght' className='pr_col'>
          <h2 id='rght'>$200/hour</h2>
          <br />
          <button >Call Now</button>
        </div>
      </div>
    </div>
    <br />
    <div className='pr__bx'>
      <div className='pr_row'>
        <div className='pr_col'>
          <h2>Plumbing Services</h2>
          <br />
          <p>For individuals that need advanced recording & editing.
          </p>
        </div>
        <div  id='rght' className='pr_col'>
          <h2 id='rght'>$175/hour</h2>
          <br />
          <button >Call Now</button>
        </div>
      </div>
    </div>
    <br />
    <div className='pr__bx'>
      <div className='pr_row'>
        <div className='pr_col'>
          <h2>Electrical Services</h2>
          <br />
          <p>For teams that need advanced sharing & reporting.

          </p>
        </div>
        <div  id='rght' className='pr_col'>
          <h2 id='rght'>$225/hour</h2>
          <br />
          <button >Call Now</button>
        </div>
      </div>
    </div>
    <br />
    <div className='pr__bx'>
      <div className='pr_row'>
        <div className='pr_col'>
          <h2>General Maintenance & Repairs</h2>
          <br />
          <p>For individuals that need advanced recording & editing.

          </p>
        </div>
        <div  id='rght' className='pr_col'>
          <h2 id='rght'>$150/hour</h2>
          <br />
          <button >Call Now</button>
        </div>
      </div>
    </div>
    <br />
    <div className='pr__bx'>
      <div className='pr_row'>
        <div className='pr_col'>
          <h2>Emergency Services (Priority Service)</h2>
          <br />
          <p>For teams that need advanced sharing & reporting.


          </p>
        </div>
        <div  id='rght' className='pr_col'>
          <h2  id='rght'>$225/hour</h2>
          <br />
          <button >Call Now</button>
        </div>
      </div>
    </div>
  </div>
</section>
<br /><br />
<section className='sec8'>
<div className='sec8__div'>
  <h2>Ready to Elevate <br />Your Facility Management? </h2>
  <br />
  <p>Our team is standing by to discuss your facility <br /> needs and develop a custom solution for your business. </p>
 <br />
  <button>Call Us Now</button>
</div>
</section>
        </div>
  )
}
export default Price;